<template>
  <!-- NFT List -->
  <div class="row my-nested q-mt-xl q-mb-xl">
    <div
      v-for="k in allnfts"
      :key="k.id"
      class="col-xs-12 col-sm-6 col-md-3 col-lg-3"
    >
      <!-- <a :href="'/token/' + k.contract + '/' + k.id"> -->
        <q-card
          class="q-ma-md q-pa-xl my-card text-center"
          style="padding-bottom: 3rem"
        >
          <!-- <q-img
            class="my-img q-pa-md"
            :ratio="1"
            :src="k.data.image"
            :alt="k.data.description"
          >
          </q-img> -->
          <q-card-section>
            <div class="my-member-name">{{ k.data.name }}</div>
            <div class="my-member-price">${{ k.data.price }}</div>
            <hr
              style="width: 80%; border: 1px solid rgba(255, 255, 255, 0.4)"
            />

            <!-- <div class="text-body2">
              {{ $t("issue_amount") }}: {{ k.data.count }}
            </div> -->
            <div class="my-member-desc" style="height: 3rem">
              {{ k.data.description }}
            </div>
          </q-card-section>
          <q-btn
            color="primary"
            rounded
            class="q-pa-sm q-pl-xl q-pr-xl"
            style="border: 1px white solid; backtround: rgba(0, 0, 0, 0)"
            @click="buynow(k)"
          >
            {{ $t("buy") }}
          </q-btn>
        </q-card>
      <!-- </a> -->
    </div>
  </div>
  <a href="https://opensea.io/assets/matic/0xa8188087ea906c1c9988cb98d76ad365b86db919/112041036217661620199639149921197448930950369665195031350235702084656298239533">
    <div class="text-h6 text-center">{{$t("member_info")}}</div>
  </a>

</template>
<style lang="sass" scoped>
.my-card
  border-radius: 15px
  padding: 10px
  margin-bottom: 10px
  .my-nft-name
    font-size: 18px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  .my-card-img
    border-radius: 15px

.my-member-name
  font-size: 24px
  margin-top: 1rem

.my-member-price
  font-family: Raleway
  font-style: normal
  font-weight: bold
  font-size: 48px
  line-height: 64px
  font-feature-settings: 'pnum' on, 'lnum' on
  margin-top: 1rem
  margin-bottom: 1rem

.my-member-desc
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 24px
</style>

<script>
import { supabase as s } from "../supabase";
import { ref, onBeforeMount, onMounted, inject } from "vue";
import { WEBROOT, APIURL, next_tid, next_token_id, chainscan } from "../web3";
import ax from "axios";
import { likeit } from "../web3";

export default {
  name: "Member",
  props: {
    count: {
      type: Number,
      default: 4,
    },
  },
  setup(props) {
    let allnfts = ref(null);
    const member_contract = "0x3324F46D3E05ADC9B0395769113264aa2749E372";
    const q = inject("q");

    onBeforeMount(async () => {
      {
        let { data: tokens, error } = await s
          .from("tokens")
          .select("*")
          .eq("contract", member_contract)
          .order("created_at", { ascending: true });
        if (error) console.log(error);
        console.log(tokens);

        allnfts.value = tokens;
      }
    });

    return {
      allnfts,
      likeit,
      member_contract,
      user: s.auth.user(),
      q,
    };
  },

  methods: {
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
    login() {
      console.log("call login");
      console.log("src:", window.location.pathname);
      alert(window.location.pathname);
    },

    async buynow(token) {
      if (!this.user) {
        this.$router.push({
          path: "/login",
          query: {
            src: window.location.pathname,
          },
        });
        return;
      }
      const to_addr = this.user.user_metadata.wallet;
      // Insert TXN record
      const record = {
        token: token.id,
        ufrom: "cfdaecc4-049b-4099-9227-8a6aed91b227",
        uto: this.user.id,
        contract: this.member_contract,
        tid: token.tid,
        from: "0xffb2ca78e8e72411e82d8684ac752846868d4f86",
        to: to_addr,
        price: token.data.price,
        type: "MEMBER",
        status: "PENDING",
      };
      console.log(record);
      const { data, error } = await s.from("txns").insert([record]);

      if (error) {
        console.log(error);
        return;
      } else {
        console.log(data[0]);
        this.$router.push({
          path: "/checkout/" + data[0].id,
          query: {
            tid: token.tid,
          },
        });
      }
    },
  },
};
</script>
