import { render } from "./Member.vue?vue&type=template&id=263d387a&scoped=true"
import script from "./Member.vue?vue&type=script&lang=js"
export * from "./Member.vue?vue&type=script&lang=js"

import "./Member.vue?vue&type=style&index=0&id=263d387a&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-263d387a"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QImg,QCardSection,QBtn});
